import React, { useEffect, useLayoutEffect, useMemo } from "react";
import Scroll from "react-scroll";
import backgroundImage from "images/banners/bg-on-premise-2x.png";
import styled, { ThemeProvider } from "styled-components";
import useHasMounted from "hooks/useHasMounted";
import {
  BannerTitle,
  Instruments,
  Layout,
  PageContainer,
  SEO,
} from "components";
import { Col, Row } from "reactstrap";
import { device } from "utils";
import { useMediaQueries } from "@react-hook/media-query";

// useTopNav:
// TODO: useMediaQuery('only screen and (max-width: 899px)');

// Theme switcher
// If the user zooms the page, the computed font size will stay constant.
// If they change the default font size in their setting however it will change
const themes = {
  sm: {
    themeName: "sm",
    useTopNav: true,
    instrumentOrientation: "vertical",
  },
  md: {
    themeName: "md",
    useTopNav: true,
    staggerHexIconsAcrossCategories: true,
    instrumentOrientation: "horizontal",
  },
  lg: {
    themeName: "lg",
    staggerHexIconsAcrossCategories: true,
    instrumentOrientation: "horizontal",
  },
  xl: {
    themeName: "xl",
    staggerHexIconsAcrossCategories: true,
    instrumentOrientation: "horizontal",
  },
};

function ThemeSwitcher(props) {
  const { matches } = useMediaQueries({
    ...device,
  });

  const theme = useMemo(() => {
    let themeOverride;
    if (matches.xl) {
      themeOverride = themes.xl;
    } else if (matches.lg) {
      themeOverride = themes.lg;
    } else if (matches.md) {
      themeOverride = themes.md;
    } else {
      themeOverride = themes.sm;
    }
    if (themeOverride) {
      return (parentTheme) => ({
        ...parentTheme,
        ...themeOverride,
      });
    }
    return (parentTheme) => parentTheme;
  }, [matches]);

  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
}

const InstrumentationPage = ({ location }) => {
  const hasMounted = useHasMounted();
  const title = "Scientific Instrumentation";
  const description =
    "Review the 180+ scientific instruments available online including separations, transfer, synthesis, spectroscopy, mass spectrometry, bioassay";

  useLayoutEffect(() => {
    let anchorTarget = "";
    setTimeout(() => {
      if (location && location.hash) {
        anchorTarget = location.hash.slice(1);
        const mainSections = document.querySelectorAll("[data-anchor-id]");
        const elementArray = Array.prototype.slice.call(mainSections, 0);
        const sectionNames = elementArray.map((node) => node.id);
        if (anchorTarget) {
          if (sectionNames.some((el) => el.includes(anchorTarget))) {
            Scroll.scroller.scrollTo(anchorTarget, {
              duration: 0,
              smooth: "easeOutCubic",
              offset: -54,
            });
          } else {
            Scroll.scroller.scrollTo(anchorTarget, {
              duration: 0,
              smooth: "easeOutCubic",
              offset: -60,
            });
          }
        }
      }
    }, 1000);
  }, [hasMounted, location]);

  if (!hasMounted) {
    return null;
  }

  return (
    <Layout clearNav location={location}>
      <ThemeSwitcher>
        <SEO title={title} description={description} />
        <BannerTitle image={backgroundImage}>{title}</BannerTitle>
        <PageContainer data-cy="instrumentation-page">
          <S.Row>
            <S.Col xs={12} md={12} lg={10}>
              <Title>
                Remotely control a complete life sciences laboratory from your
                computer
              </Title>
              <p>
                Emerald's Command Center software puts you at the helm of a vast
                ECL facility where you can orchestrate as many experiments in
                parallel as you’d like while sitting comfortably at your
                computer from anywhere in the world. Interactively direct the
                activity of any of the instrumentation or equipment listed using
                experiment commands on demand or build larger scripts that
                automatically direct the progression of samples across a series
                of experiment commands.
              </p>
            </S.Col>
          </S.Row>
          <Instruments />
        </PageContainer>
      </ThemeSwitcher>
    </Layout>
  );
};

export default InstrumentationPage;

const S = {};

S.Row = styled(Row)`
  margin-top: 5rem;
`;

S.Col = styled(Col)`
  padding-left: 0;
  margin-bottom: 1.7rem;
`;

const Title = styled.h2`
  margin-bottom: 1rem;
  color: #1d242a;
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 2.7rem;
`;
